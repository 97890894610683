import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { addStocks, getSearchStockDetails, getStockList, searchStocks } from '../../../services/HomeService'
// import { getPortfoloio } from '../../../services/PortfolioService'
// import { getProviders } from '../../../services/ProviderServices'
// import CreatePortfolio from '../../commonComponent/commonModels/StockModels/CreatePortfolio'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import { getStockStats } from '../../../services/TradeService'
import { GetProviderstocks } from '../../../services/ProviderServices';
import { toast } from 'react-toastify';
import $ from "jquery";
import moment from 'moment';
import { getCountryListAPI, getExchangeRateAPI } from '../../../services/commonServices';
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';


const CreateTrade = ({ strategyList, providerList, setTradeListRun, allData }) => {
    const [addStockData, setAddStockData] = useState({
        userId: "",
        country: "",
        providerName: "",
        providerId: '',
        stockId: '',
        stockCode: "",
        tradeType: "",
        direction: true,
        entry: '',
        stopLoss: '',
        target: '',
        closed: '',
        price: '',
        changes: '',
        positionStatus: false,
        tradeDate: new Date(),
        margin: '',
        Brokrage: '',
        stockName: '',
        TradingSystemId: '',
        type: 'TRADES',
        imgFile: '',
        Short: '', ask_size: 0, ask_price: 0, bid_price: 0, bid_size: 0,
        reRender: 0
    })
    const [selectStock, setSelectStock] = useState()
    const [searchList, setSearchList] = useState([])
    const [stocksList, setStocksList] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [longShort, setLongShort] = useState('buy')
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [disableSave, setDisableSave] = useState(false)
    const [showStockListDropDown, setShowStockListDropDown] = useState(false)
    const [stockListPaging, setStockListPaging] = useState({ PageNumber: 1, PageSize: 100, search: '' })
    const { PageNumber, PageSize, search } = stockListPaging
    const [error, setError] = useState({ target: true, stopLoss: true })
    const { reRender, ask_size, type, ask_price, bid_price, bid_size, imgFile, userId, country, entry, providerId, stockName, Brokrage, providerName, margin, stockId, stockCode, tradeType, direction, stopLoss, target, closed, price, changes, positionStatus } = addStockData
    const [selectCodeLoad, setSelectCodeLoad] = useState(false)
    const [countryList, setCountryList] = useState()
    const [stockcountrycurrency, setStockCountryCurrency] = useState('')

    useEffect(() => {
        try {
            getCountryListAPI().then((res) => {
                if (res?.status === 200) {
                    setCountryList(res.data)
                }
            })
        } catch (error) {

        }
    }, [])




    const getProviderStocksList = () => {

        if (providerId !== '' && providerId !== undefined) {
            let data = {
                PageNumber: PageNumber,
                PageSize: PageSize,
                id: providerId,
                search: search
            }
            try {
                GetProviderstocks(data).then((res) => {
                    if (res?.status === 200) {
                        setStocksList(res.data.data)

                    }
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {

        getProviderStocksList()
    }, [providerId, search, PageSize])
    const handleSearchStocks = (e) => {
        setSearchKey(e.target.value)
        let a = e.target.value.replace("/", "_")
        if (a.length > 2) {

            let data = {
                a, providerId
            }
            try {
                searchStocks(data).then((res) => {
                    setSearchList(res.data)
                    setStocksList(res.data)
                })
            } catch (error) {

            }
        } else {
            setSearchList([])
        }
    }
    const handleSearchSelectStock = (value, type) => {

       
        setSelectCodeLoad(true)
        let stockArr = value.stockCode.split('_')
        let newstockArr = []
        if (stockArr[3] == 'USDC' || stockArr[3] == 'USDT' || stockArr[3] == 'USDB') {
            newstockArr = 'USD'
        }
        else {
            newstockArr = stockArr[3]
        }
        // let stockCountryCode = countryList.filter((val) => val.key.toUpperCase() == value?.country.toUpperCase())
        let stockCurrency = CountryCurrencyList.filter((val) => val.code == newstockArr)
        setStockCountryCurrency(stockCurrency[0]?.symbol_native)
        try {
            getExchangeRateAPI(stockArr[2], newstockArr).then((respons) => {
                if (respons.data && respons.data["Realtime Currency Exchange Rate"]) {
                const exPrice = respons.data["Realtime Currency Exchange Rate"]["5. Exchange Rate"];
                const bidPrice = respons.data["Realtime Currency Exchange Rate"]["8. Bid Price"];
                const askPrice = respons.data["Realtime Currency Exchange Rate"]["9. Ask Price"];
                if (askPrice && bidPrice) {
                    const twoDecimal = (value) => {
                        var roundedNumber = value;
                        var decimalIndex = roundedNumber.indexOf(".");
                        if (decimalIndex !== -1) {
                            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                            if (decimalPlaces > 3) {
                                roundedNumber = roundedNumber.slice(0, decimalIndex + 4);
                            }
                        }
                        return roundedNumber
                    }
                    // debugger
                    setSearchList([])
                    setSearchKey('')
                    let data = {
                        askPrice: twoDecimal(askPrice),
                        bidPrice: twoDecimal(bidPrice),
                        symbol_id: stockArr[2]
                    }
                    setSelectStock(data)
                    // providerId

                    let pName = providerList.filter((el) => {
                        return (
                            el.providerId === value.providerId
                        )
                    })
                    setShowStockListDropDown(false)

                    if (type == 'buy') {
                        setAddStockData({ ...addStockData, stockName: stockArr[2], stockCode: value?.stockCode, providerId: value.providerId, stockId: value.stockId, entry: twoDecimal(askPrice), ask_size: 0, ask_price: twoDecimal(askPrice), bid_price: twoDecimal(bidPrice), bid_size: 0, providerName: pName[0]?.providerName, country: value.country, margin: value.margin })
                    } else {
                        setAddStockData({ ...addStockData, stockName: stockArr[2], stockCode: value?.stockCode, providerId: value.providerId, stockId: value.stockId, entry: twoDecimal(bidPrice), ask_size: 0, ask_price: twoDecimal(askPrice), bid_price: twoDecimal(bidPrice), bid_size: 0, providerName: pName[0]?.providerName, country: value.country, margin: value.margin })
                    }

                } else {

                    setAddStockData({
                        ...addStockData,
                        country: "",
                        imgFile: '',
                        tradeType: "",
                        type: addStockData.type,
                        direction: true,
                        stopLoss: '',
                        target: '',
                        closed: '',
                        price: '',
                        changes: '',
                        positionStatus: false,
                        tradeDate: new Date(),
                        margin: '',
                        Short: '', ask_size: 0, ask_price: 0, bid_price: 0, bid_size: 0
                    })
                }
            }
            })
        } catch (error) {

        }


        setSelectCodeLoad(false)
        // try {
        //     getStockStats(value.stockCode).then((res) => {
        //         if (res?.status === 200) {

        //             setStockStats(res.data[0])
        //         }
        //     })
        // } catch (error) {

        // }
        // setStockStats
    }
    useEffect(() => {

        if (stockId == '') {
            setSelectStock()
        }
    }, [stockId])
    const handleLongShort = (val) => {
        setError({ target: true, stopLoss: true })
        if (val === 'buy') {
            setLongShort(val)
            // if (addStockData.stockCode !== '') {
            //     handleSearchSelectStock(addStockData, val)
            // }
        } else {
            setLongShort(val)
            // if (addStockData.stockCode !== '') {
            //     handleSearchSelectStock(addStockData, val)
            // }
        }
        setAddStockData({ ...addStockData, target: '', stopLoss: '', closed: '' })
    }

    useEffect(() => {

    })
    const twoDecimal = (value) => {

        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 5) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 6);
            }
        }
        return roundedNumber
    }

    const hanleInputData = (e) => {

        if (e.target.name == 'providerId') {
            let pName = providerList.filter((el) => {
                return (
                    el.providerId == e.target.value
                )
            })
            setSelectStock({})


            setAddStockData({ ...addStockData, [e.target.name]: pName[0]?.providerName, providerId: e.target.value, stockId: '', stockCode: '', stockName: '', entry: '', ask_size: '', ask_price: '', bid_price: '', bid_size: '', country: '', margin: '' })
        }
        else if (e.target.name == 'stockId') {
            let stCode = stocksList.filter((el) => {
                return (
                    el.stockId == e.target.value
                )
            })

            // handleSearchSelectStock(stCode[0])
            setAddStockData({ ...addStockData, [e.target.name]: e.target.value, stockCode: stCode[0]?.stockCode })

        } else if (e.target.name == 'entry') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (providerId == '' || providerId == undefined) {
                    toast.error('Select provider first.')
                } else if (stockCode == '') {
                    toast.error('Select crypto first.')
                } else {

                    let n = e.target.value.split('.')
                    var roundedNumber = twoDecimal(e.target.value);
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, closed: '', stopLoss: '', target: '' })
                        }
                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, closed: '', stopLoss: '', target: '' })
                    }

                }
            }
        } else if (e.target.name == 'closed') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (providerId == '' || providerId == undefined) {
                    toast.error('Select provider first.')
                } else if (stockCode == '') {
                    toast.error('Select crypto first.')
                } else if (entry == '' || entry == 0) {
                    toast.error('Enter entry price first.')
                } else {
                    // debugger
                    let n = e.target.value.split('.')
                    var roundedNumber = twoDecimal(e.target.value);
                    // var roundedNumber =    removeDecimalZero(e.target.value);
                    // console.log(roundedNumber ,"roundedNumber")
                    if (longShort == 'sell') {
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                            }
                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                        }
                    } else {
                        if (Number(e.target.value) < Number(entry)) {

                            if (n.length == 2) {
                                if (n[0].length <= 10) {
                                    setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                                }
                            } else if (n.length == 1 && e.target.value.length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber, stopLoss: roundedNumber })
                            }
                        } else {
                            toast.error('Set lower stop loss price than entry while long trading')
                        }
                    }
                }

            }
            // if (longShort == 'sell') {
            //     setAddStockData({ ...addStockData, [e.target.name]: e.target.value, stopLoss: e.target.value })
            // } else {
            //     if (Number(e.target.value) < Number(entry)) {
            //         setAddStockData({ ...addStockData, [e.target.name]: e.target.value, stopLoss: e.target.value })
            //     } else {
            //         toast.error('Set lower stop loss price than entry while buy trading')
            //     }
            // }
        } else if (e.target.name == 'target') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                if (providerId == '' || providerId == undefined) {
                    toast.error('Select provider first.')
                } else if (stockCode == '') {
                    toast.error('Select crypto first.')
                } else if (entry == '' || entry == 0) {
                    toast.error('Enter entry price first.')
                } else {
                    // if (longShort == 'buy') {
                    //     setAddStockData({ ...addStockData, [e.target.name]: e.target.value })
                    // } else {
                    //     if (Number(e.target.value) < Number(entry)) {
                    //         setAddStockData({ ...addStockData, [e.target.name]: e.target.value })
                    //     } else {
                    //         toast.error('Set lower target price than entry while sell trading')
                    //     }
                    // }


                    let n = e.target.value.split('.')
                    var roundedNumber = twoDecimal(e.target.value);
                    if (longShort == 'buy') {
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                            }
                        } else if (n.length == 1 && e.target.value.length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                        }
                    } else {
                        if (Number(e.target.value) < Number(entry)) {
                            if (n.length == 2) {
                                if (n[0].length <= 10) {
                                    setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                                }
                            } else if (n.length == 1 && e.target.value.length <= 10) {
                                setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                            }
                        } else {
                            toast.error('Set lower target price than entry while short trading')
                        }
                    }
                }
            }
        } else if (e.target.name == 'margin') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {

                if (providerId == '' || providerId == undefined) {
                    toast.error('Select provider first.')
                } else if (stockCode == '') {
                    toast.error('Select crypto first.')
                } else {

                    var roundedNumber = twoDecimal(e.target.value);
                    let n = e.target.value.split('.')
                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                        }
                    } else if (n.length == 1 && e.target.value.length <= 10) {
                        setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                    }

                }
            }
        } else if (e.target.name == 'files') {
            setAddStockData({
                ...addStockData, imgFile: e.target.files[0],
                imgUrl: URL.createObjectURL(e.target.files[0])
            })
        } else if (e.target.name == 'Brokrage' || e.target.name == 'changes') {
            if (Number(e.target.value) >= 0 || e.target.value == '.') {
                let n = e.target.value.split('.')
                var roundedNumber = twoDecimal(e.target.value);
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                    }
                } else if (n.length == 1 && e.target.value.length <= 10) {
                    setAddStockData({ ...addStockData, [e.target.name]: roundedNumber })
                }

            } else if (e.target.value == '') {
                setAddStockData({ ...addStockData, [e.target.name]: e.target.value })
            }

        } else {
            setAddStockData({ ...addStockData, [e.target.name]: e.target.value })
        }
    }

    const handleCreateStock = (value) => {
        const formData = new FormData();
        let apiData = {
            ask_size: (addStockData.ask_size).toString(),
            ask_price: (addStockData.ask_price).toString(),
            bid_price: (addStockData.bid_price).toString(),
            bid_size: (addStockData.bid_size).toString(),
        }
        // let n=moment(selectedDate).format('DD MMM YYYY')
        formData.append('UserId', addStockData.userId);
        formData.append('ask_size', addStockData.ask_size);
        formData.append('ask_price', addStockData.ask_price);
        formData.append('bid_price', addStockData.bid_price);
        formData.append('bid_size', addStockData.bid_size);
        formData.append('Country', addStockData.country.toUpperCase());
        formData.append('ProviderName', addStockData.providerName);
        formData.append('TradingSystemId', addStockData.TradingSystemId);
        formData.append('StockId', addStockData.stockId);
        formData.append('StockCode', addStockData.stockCode);
        formData.append('TradeType', addStockData.tradeType);
        formData.append('Direction', 'false');
        formData.append('Short', longShort);
        formData.append('quantity', '0');
        formData.append('EntryPrice', addStockData.entry);
        formData.append('StopLoss', addStockData.stopLoss);
        formData.append('Target', addStockData.target);
        formData.append('Closed', addStockData.closed);
        formData.append('Price', addStockData.price);
        formData.append('Changes', addStockData.changes);
        formData.append('PositionStatus', false);
        formData.append('TradeDate', moment(selectedDate).format('DD MMM YYYY'));
        formData.append('Margin', addStockData.margin);
        formData.append('Brokrage', addStockData.Brokrage);
        formData.append('StockName', addStockData.stockCode);
        formData.append('Action', '');
        formData.append('Strategy', '');
        formData.append('Type', addStockData?.type);
        formData.append('ProviderID', addStockData?.providerId);
        formData.append('file', addStockData.imgFile);

        try {
            addStocks(formData).then((res) => {
                if (res?.status === 200) {
                    setTradeListRun(true)
                    let cancle = document.getElementById('cancel-button-edit')
                    cancle.click()
                }
                setDisableSave(false)
            })
        } catch (error) {
            setDisableSave(false)
        }
    }


    const handleRemoveAllData = () => {
        setAddStockData({
            userId: "1",
            country: "",
            type: "TRADES",
            providerName: "",
            entry: '',
            imgFile: '',
            providerId: '',
            stockId: '',
            stockCode: "",
            tradeType: "",
            direction: true,
            stopLoss: '',
            target: '',
            closed: '',
            price: '',
            changes: '',
            positionStatus: false,
            tradeDate: new Date(),
            margin: '',
            Brokrage: '',
            stockName: '',
            TradingSystemId: '',
            quantity: 0,
            Strategy: '',
            Short: '',
            ask_size: 0,
            ask_price: 0,
            bid_price: 0,
            bid_size: 0,
            reRender: reRender + 1
        })
        setLongShort('buy')
        setError({ target: true, stopLoss: true })
        setSelectStock()
        setStocksList([])
        document.getElementById('imgFile').value = ''
    }

    // const handleScroll = () => {
    // }

    // setTimeout(() => {
    //     let scrollid = document.getElementById('selectScroll')
    //     scrollid.addEventListener('scroll', handleScroll)
    // }, 1000)


    const filterWeekends = (date) => {
        // Get the day of the week (0-6) for the provided date
        const day = date.getDay();
        // If the day of the week is Saturday (6) or Sunday (0), disable the date
        return day !== 0 && day !== 6;
    };
    let validationSchema = Yup.object().shape({
        country: Yup.string().required('Country is required'),
        providerId: Yup.string().required('Provider name is required'),
        TradingSystemId: Yup.string().required('Strategy is required'),
        stockId: Yup.number().required('Crypto code is required'),
        stopLoss: Yup.number().required('Stoploss is required').typeError('Stoploss must ba a number.'),
        entry: Yup.number().required('Entry is required').typeError('Entry price must ba a number.'),
        target: Yup.number().required('Target is required').typeError('Target must ba a number.'),
        closed: Yup.number().required('Stop loss is required').typeError('Cap must ba a number.'),
        changes: Yup.number().required('Exchange rate is required').typeError('Exchange must ba a number.'),
        margin: Yup.number().required('Margin is required').typeError('Margin must ba a number.'),
        Brokrage: Yup.number().required('Brokrage is required').typeError('Brokrage must ba a number.'),
    });
    const renderError = (message) => <p className="help is-danger">{message}</p>
    // const Formik=useFormik({initialValues:{ ...addStockData },
    //     validationSchema:{validationSchema},
    //     onSubmit: values => {
    //         alert(JSON.stringify(values, null, 2));
    //       },    
    // })

    const handleShowHideStockList = () => {

        setShowStockListDropDown(!showStockListDropDown)
        setOpen(false)

    };
    var isLoading = false; // Track if data is currently being loaded
    function loadData() {
        isLoading = true; // Set loading state
        setStockListPaging({ ...stockListPaging, PageNumber: 1, PageSize: 100 + PageSize })
    }
    $(function () {
        var selectElement = document.getElementById('selectScroll');
        var page = 1; // Track the page number for pagination
        // Event handler for scroll event on select element
        selectElement?.addEventListener('scroll', () => {
            if (selectElement.scrollTop + selectElement.clientHeight >= selectElement.scrollHeight) {
                // User has scrolled to the bottom
                // Load more data if not already loading
                if (!isLoading) {
                    loadData()
                }
            }
        });
    });
    // Event handler for scroll event on select element


    // Function to load additional data from the API
    const handleSelectInputStock = (e) => {
        if (providerId == '' || providerId == undefined) {
            toast.error('Select provider first.')
        } else {
            setStockListPaging({ ...stockListPaging, search: e.target.value })
            setAddStockData({ ...addStockData, stockCode: e.target.value })
            setShowStockListDropDown(true)
        }
    }
    const handleBlur = () => {
        setTimeout(() => {
            setShowStockListDropDown(false)
        }, 500)
    }

    // start 02-06-2023
    const [isOpen, setOpen] = useState(false);
    const [isOpen2, setOpen2] = useState(false);
    const [items, setItem] = useState(providerList);
    const [selectedItem, setSelectedItem] = useState(null);


    const toggle = () => setOpen2(!isOpen2);
    const toggleDropdown = () => {
        setOpen(!isOpen)
        setShowStockListDropDown(false)
    };



    const handleItemClick = (data) => {

        setAddStockData({ ...addStockData, providerId: data.providerId, providerName: data.providerName, stockId: '', stockCode: '', stockName: '', entry: '', ask_size: '', ask_price: '', bid_price: '', bid_size: '', country: '', margin: '' })
        setOpen(false);
        //   selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
    }

    const handleItemClick2 = (data) => {

        setAddStockData({ ...addStockData, TradingSystemId: data.tradingSystemId, name: data.name })
        setOpen2(false);
        //   selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
    }

    const handleCheckValidation = (e) => {


        if (Number(e.target.value) >= 0) {
            if (entry !== '') {
                if (e.target.name == 'target') {
                    if (longShort === 'buy') {
                        if (Number(entry) > Number(e.target.value)) {
                            toast.error('Set higher target price than entry while buy trading')
                            setError({ ...error, target: false })
                        } else {
                            setError({ ...error, target: true })
                        }
                    } else {
                        setError({ ...error, target: true })
                    }
                } else {
                    if (longShort === 'sell') {
                        if (Number(entry) >= Number(e.target.value)) {
                            toast.error('Set higher stop loss price than entry while sell trading')
                            setError({ ...error, stopLoss: false })
                        } else {
                            setError({ ...error, stopLoss: true })
                        }
                    } else {
                        setError({ ...error, stopLoss: true })
                    }
                }
            }
        }
    }
    $(document).ready(function () {
        // Attach keypress event handler to the input field
        $('#numericInput').on('keypress', function (event) {
            // Get the current value of the input field
            const currentValue = $(this).val();

            // Allow only numeric characters, the backspace key (keyCode 8), and a single dot (decimal point)
            if (
                event.which !== 8 &&
                event.key !== '.' &&
                (isNaN(String.fromCharCode(event.which)) || (event.key === '.' && currentValue.includes('.')))
            ) {
                event.preventDefault();
            }
        });
    });
    //end 02-06-2023
    const handleBlurCreateTrade = () => {

    }
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdownO > .captionO").length === 0) {
                setShowStockListDropDown(false)
            }
        });
    });
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdown1 > .caption1").length === 0) {
                setOpen(false)
            }
        });
    });
    $(function () {
        $(document).on('click', function (evt) {

            if ($(evt.target).closest(".dropdown2 > .caption2").length === 0) {
                setOpen2(false)
            }
        });
    });
    return (
        <>
            <div className="modal fade backdrop-1" id="createTrade" data-bs-backdrop="static" role="dialog">
                <div className="modal-dialog modal-lg  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title h4" id="exampleModalLgLabel">Create Trade</h5>
                            <button type="button" className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close" onClick={handleRemoveAllData}>×</button>
                        </div>

                        <div className="modal-body blackout-bx">
                            {/* <!-------modeal-----> */}

                            <div className="row">


                                {/* <div className="col-lg-12">
                                    <p>Search by company name or code <Link to='' className="float-end" >or  <span style={{ color: "#ff8038" }}>+ Add a custom instrument</span></Link> </p>
                                </div> */}


                                <div className="col-lg-12 mb-4">
                                    <div className="search">
                                        <i className="bi bi-search" type="submit"></i>
                                        <input type="text" value={searchKey} onChange={handleSearchStocks} className="form-control" placeholder="Search Crypto" />
                                        {
                                            searchList.length > 0 && <div className='dropdown-search'  >
                                                {
                                                    searchList.map((val, ind) => {
                                                        let stockArr = val.stockCode.split('_')

                                                        return (
                                                            <p key={ind} onClick={() => handleSearchSelectStock(val, longShort)}>{stockArr[2] + '/' + stockArr[3]}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>


                                {
                                    selectStock !== undefined && <div className="col-lg-12">
                                        <div className="rate-bx">
                                            <div class='d-flex'>
                                                <h2>{selectStock.symbol_id}
                                                    {/* <span className="float-end"> NVIDIA Corp</span> */}
                                                </h2>
                                                {/* <p className='ps-5'>Ps Ratio: {(stockStats?.peRatio)?.toFixed(3)} &nbsp;Dividend Yield: {(stockStats?.dividendYield)?.toFixed(3)}</p> */}
                                            </div>

                                            <h2 className="pt-3">ASK {stockcountrycurrency} {selectStock.askPrice}    <span className="float-end">BID {stockcountrycurrency} {selectStock.bidPrice} </span> </h2>
                                        </div>
                                    </div>
                                }
                                <Formik
                                    initialValues={addStockData}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                    onSubmit={(value) => {
                                        setDisableSave(true)
                                        handleCreateStock(value)
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form className="form-bx mt-4" >

                                            <div className="row">
                                                {/* <!-------row-----> */}

                                                <div className="col-lg-6 mt-2">
                                                    <label for="formGroupExampleInput" className="form-label">Trade Type <span style={{ "color": "#f00" }}>*</span></label>
                                                    <div className="d-flex justify-content-between">
                                                        <div class="form-check ">
                                                            <input class="form-check-input tradeType" type="radio" value="" id="flexCheckCheckedDisabled" checked={type == 'TRADES'} onClick={() => setAddStockData({ ...addStockData, type: 'TRADES' })} />
                                                            <label class="form-check-label" for="flexCheckCheckedDisabled">
                                                                TRADES
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input tradeType" type="radio" value="" id="flexCheckCheckedDisabled" checked={type == 'TRENDS'} onClick={() => setAddStockData({ ...addStockData, type: 'TRENDS' })} />
                                                            <label class="form-check-label" for="flexCheckCheckedDisabled">
                                                                TRENDS
                                                            </label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input tradeType" type="radio" value="" id="flexCheckCheckedDisabled" checked={type == 'HODL'} onClick={() => setAddStockData({ ...addStockData, type: 'HODL' })} />
                                                            <label class="form-check-label" for="flexCheckCheckedDisabled">
                                                                HODL
                                                            </label>
                                                        </div>
                                                        {/* <label for="formGroupExampleInput" className="form-label">Country</label>
                                                        <Field type="text" name='country' className="form-control" onChange={(e) => { hanleInputData(e) }} id="formGroupExampleInput" />
                                                        {errors.country && touched.country ? (
                                                            <div className='color-msg-err'>{errors.country}</div>
                                                        ) : null} */}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 ms-auto">
                                                    {/* start 02-06-2023 */}

                                                    <div className="mb-3 a dropdown1">
                                                        <label for="formGroupExampleInput" className="form-label">Provider Name <span style={{ "color": "#f00" }}>*</span></label>
                                                        <div className='dropdown caption1'>
                                                            <div className='dropdown-header background_white' onClick={toggleDropdown}>
                                                                {selectedItem ? items.find(item => item.providerId == selectedItem)?.label : addStockData?.providerName || "Select"}
                                                                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
                                                            </div>
                                                            <div className={`dropdown-body ${isOpen && 'open'}`} as='select' onChange={(e) => { hanleInputData(e) }}>
                                                                {providerList.map(item => (
                                                                    <div className="dropdown-item" onClick={e => handleItemClick(item)} id={item.providerId}>
                                                                        <span className={`dropdown-item-dot ${item.providerId == selectedItem && 'selected'}`}> </span>
                                                                        {item.providerName}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        {errors.providerId && touched.providerId ? (
                                                            <div className='color-msg-err'>{errors.providerId}</div>
                                                        ) : null}

                                                        {/*                                                         
                                                        <Field name='providerId' as='select' className="form-select" onChange={(e) => { hanleInputData(e) }}>
                                                            <option value="0" style={{ 'visibility': "hidden"}}>Select Provider</option>
                                                            {
                                                                providerList.map((val, ind) => {
                                                                    return (
                                                                        <option key={ind} value={(val.providerId)} >{val.providerName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Field> 
                                                        */}
                                                    </div>

                                                    {/* end 02-06-2023 */}
                                                </div>

                                                <div className="col-lg-6 ">
                                                    <div className="mb-3">
                                                        <label for="formGroupExampleInput" className="form-label">Trade Date <span style={{ "color": "#f00" }}>*</span></label>
                                                        <DatePicker
                                                            className='date-picker ps-2'
                                                            selected={selectedDate}
                                                            onChange={(date) => setSelectedDate(date)}
                                                            // minDate={new Date()}
                                                            filterDate={filterWeekends}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 ms-auto">

                                                    <div className="long-shr-btn ">
                                                        <label for="formGroupExampleInput" className="form-label">Direction</label>
                                                        <nav className="menu">
                                                            <button type='button' className={longShort == 'buy' ? "menu-link menu-active-create flex-fill" : "menu-link flex-fill"} onClick={() => handleLongShort('buy')}>Buy</button>
                                                            <button type='button' className={longShort == 'sell' ? "menu-link menu-active-create flex-fill" : "menu-link flex-fill"} onClick={() => handleLongShort('sell')}>Sell</button>
                                                        </nav>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 mt-2">
                                                    <div className="mb-3">
                                                        <label for="formGroupExampleInput" className="form-label">Margin % <span style={{ "color": "#f00" }}>*</span></label>
                                                        <Field type="text" name='margin' placeholder='0' value={margin} className="form-control" onBlur={handleBlurCreateTrade} onChange={(e) => { hanleInputData(e) }} id="formGroupExampleInput" />
                                                        {errors.margin && touched.margin ? (
                                                            <div className='color-msg-err'>{errors.margin}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 mt-2 ms-auto">
                                                    <div className="mb-3 dropdownO">
                                                        <label for="formGroupExampleInput" className="form-label">Crypto <span style={{ "color": "#f00" }}>*</span></label>

                                                        <input autocomplete={'off'} as='select' id='selectScrollInput' onClick={handleShowHideStockList} onBlur={handleBlurCreateTrade} placeholder='Select Crypto' value={stockCode} onChange={(e) => { handleSelectInputStock(e) }} className="form-select captionO" name='stockCode' />
                                                        {/* <option selected >Select Stock</option> */}
                                                        {(showStockListDropDown && !selectCodeLoad) &&
                                                            <div className='dropdown-search stockOption' id="selectScroll" >
                                                                {
                                                                    stocksList.map((val, ind) => {
                                                                        let stockArr = val.stockCode.split('_')
                                                                        return (
                                                                            <option key={ind} onBlur={handleBlur} onClick={() => handleSearchSelectStock(val, longShort)}>{stockArr[2] + '/' + stockArr[3]}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }


                                                        {errors.stockId && touched.stockId ? (
                                                            <div className='color-msg-err'>{errors.stockId}</div>
                                                        ) : null}
                                                    </div>
                                                </div>



                                                <div className="col-lg-12">
                                                    <div className="rate-bx1">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-sm-4 text-center">
                                                                <div className="value-bx1">
                                                                    <p>Entry Price <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></p>

                                                                    <Field name='entry' className='form-control' placeholder='0' value={entry} onBlur={handleBlurCreateTrade} onChange={hanleInputData} />

                                                                    {errors.entry && touched.entry ? (
                                                                        <p className='err-msg'>{errors.entry}</p>
                                                                    ) : null}
                                                                    <span class="doller-d">{stockcountrycurrency}</span>

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4 text-center">
                                                                <div className="value-bx1">
                                                                    <p>Target Price <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></p>
                                                                    <Field id='numericInput' placeholder='0' class={error.target == false ? "input-err-color form-control" : "form-control"} onBlur={handleCheckValidation} name='target' value={target} onChange={hanleInputData} />
                                                                    {errors.target && touched.target ? (
                                                                        <p className='err-msg'>{errors.target}</p>
                                                                    ) : null}
                                                                    <span class="doller-d">{stockcountrycurrency}</span>


                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-sm-4 text-center">
                                                                <div className="value-bx1">
                                                                    <p>Stop Loss <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></p>
                                                                    <Field name='closed' id='numericInput' placeholder='0' class={error.stopLoss == false ? "input-err-color form-control" : "form-control"} onBlur={handleCheckValidation} value={closed} onChange={hanleInputData} />
                                                                    {errors.closed && touched.closed ? (
                                                                        <p className='err-msg'>{errors.closed}</p>
                                                                    ) : null}
                                                                    <span class="doller-d">{stockcountrycurrency}</span>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mt-3">
                                                    <div className="row">
                                                        {/* start 02-06-2023 */}

                                                        <div className="col-sm-7 col-lg-12 a mt-4">
                                                            <label for="formGroupExampleInput" className="form-label">Strategy <span style={{ "color": "#f00", 'backgroundColor': '#faebd700' }}>*</span></label>
                                                            <div className="d-flex gap-4">
                                                                <div className='w-100 dropdown2'>
                                                                    <div className='dropdown caption2'>
                                                                        <div className='dropdown-header background_white' onClick={toggle}>
                                                                            {/* {selectedItem ? items.find(item => item.providerId == selectedItem)?.label :addStockData?.providerName||"Select"} */}
                                                                            {selectedItem ? items.find(item => item.tradingSystemId == selectedItem)?.label : addStockData?.name || "Select"}
                                                                            <i className={`fa fa-chevron-right icon ${isOpen2 && "open"}`}></i>
                                                                        </div>

                                                                        <div className={`dropdown-body ${isOpen2 && 'open'}`} as='select' onChange={(e) => { hanleInputData(e) }}>
                                                                            {strategyList?.map(item => (
                                                                                <div className="dropdown-item" onClick={() => handleItemClick2(item)} id={item.tradingSystemId}>
                                                                                    <span className={`dropdown-item-dot ${item.tradingSystemId == selectedItem && 'selected'}`}> </span>
                                                                                    {item.name}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>

                                                                    {errors.TradingSystemId && touched.TradingSystemId ? (
                                                                        <div className='color-msg-err'>{errors.TradingSystemId}</div>
                                                                    ) : null}
                                                                </div>

                                                                <button className={errors.TradingSystemId && touched.TradingSystemId ? "btn bt-light fullc-or d-block mt-0 flex-shrink-0 height_51px" : "btn bt-light fullc-or d-block mt-0 flex-shrink-0 "} type="button" data-bs-toggle="modal" data-bs-target="#addEditStrategy"> <span> <i className="bi bi-plus-lg"></i> </span> Create Strategy </button>

                                                            </div>

                                                        </div>

                                                        {/* <div className="col-sm-5 col-lg-4 mt-4">
                                                            <button className="btn bt-light fullc-or  ms-auto d-block" type="button" data-bs-toggle="modal" data-bs-target="#addEditStrategy"> <span> <i className="bi bi-plus-lg"></i> </span> Create Strategy </button>
                                                        </div> */}

                                                        {/* end 02-06-2023 */}

                                                    </div>
                                                </div>

                                                <div className="col-lg-6 mt-3">
                                                    <div className="in-w-slec">
                                                        <label for="formGroupExampleInput" className="form-label">Exchange Rate <span style={{ "color": "#f00" }}>*</span></label>
                                                        <div className="input-group ">
                                                            <Field type="text" className="form-control " placeholder='0' name='changes' value={changes} onBlur={handleBlurCreateTrade} onChange={(e) => { hanleInputData(e) }} aria-label="Recipient's username" aria-describedby="button-addon2" />

                                                            {/* <select className="form-select  " onChange={hanleInputData}>
                                                                <option selected >AUD/USD </option>
                                                            </select> */}
                                                        </div>
                                                        {errors.changes && touched.changes ? (
                                                            <div className='color-msg-err'>{errors.changes}</div>
                                                        ) : null}
                                                    </div>
                                                </div>


                                                <div className="col-lg-6 mt-3">
                                                    <div className="in-w-slec">
                                                        <label for="formGroupExampleInput" className="form-label">Brokrage <span style={{ "color": "#f00" }}>*</span></label>
                                                        <div className="input-group">
                                                            <Field type="text" name='Brokrage' placeholder='0' value={Brokrage} onChange={(e) => { hanleInputData(e) }} onBlur={handleBlurCreateTrade} className="form-control " aria-label="Recipient's username" aria-describedby="button-addon2" />

                                                            {/* <select className="form-select onChange={hanleInputData}">
                                                                <option selected >AUD</option>
                                                            </select> */}

                                                        </div>
                                                        {errors.Brokrage && touched.Brokrage ? (
                                                            <div className='color-msg-err mb-2'>{errors.Brokrage}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mt-4">
                                                    <div className="">
                                                        <label for="formGroupExampleInput" className="form-label">Upload Chart Image</label>
                                                        <div className="input-group ">
                                                            <Field type="file" id='imgFile' accept="image/*" className="form-control file_upload" name='files' onChange={hanleInputData} aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                        </div>

                                                    </div>
                                                </div>





                                                {/* <button className="btn  add-btn white" type="button"> <span> <i className="bi bi-plus-lg"></i> </span> Add comments </button> */}


                                                <div className="row mt-4">
                                                    <div className="col-lg-4 col-sm-4  mx-auto">
                                                        <button className="btn cansel-btn" data-bs-dismiss="modal" id='cancel-button-edit' onClick={handleRemoveAllData} type="button">Cancel</button>
                                                    </div>


                                                    <div className="col-lg-4   col-sm-4 mx-auto">
                                                        <button className="btn save-btn" disabled={disableSave} type="submit">Save</button>
                                                    </div>
                                                </div>



                                                {/* <button className="btn mt-5 ms-auto d-block white add-btn" type="button"> <span> <i className="bi bi-plus-lg"></i> </span>  Save & add another </button> */}





                                            </div>
                                            {/* <!-------row-----> */}
                                        </Form>
                                    )}

                                </Formik>


                            </div>

                        </div>
                        {/* <!-------modeal-----> */}



                    </div>
                </div>
            </div>
            {/* <CreatePortfolio /> */}
        </>
    )
}

export default CreateTrade